<template>
  <div>
    <div class="workplatform-title">{{ advertiser.name }} （{{ advertiser.orgCode }}）</div>
    <!-- 客户基本信息 -->
    <Row class="p-b-5 p-t-10">
      <i-col span="8" ><span class="title">客户类型 </span >{{advertiser.typeName}}</i-col >
      <i-col span="8" ><span class="title">所属媒介主 </span >{{advertiser.publisherName}}</i-col >
      <i-col span="8" ><span class="title">联系电话 </span >{{advertiser.tel||'-'}}</i-col >
      <i-col span="8" ><span class="title">客户来源 </span >{{advertiser.adFromName||'-'}}</i-col >
      <i-col span="8" ><span class="title">公司类型 </span >{{advertiser.companyTypeName||'-'}}</i-col >
      <i-col span="8" ><span class="title">报备代理商 </span >{{advertiser.reportCompanyName||'-'}}</i-col >
      <i-col span="8" ><span class="title">报备时间 </span >{{advertiser.createTime}}</i-col >
      <!-- <i-col span="8" ><span class="title">地址 </span >{{advertiser.address}}</i-col > -->
      <i-col span="8"><span class="title">客户备注信息 </span>{{advertiser.description||'-'}}</i-col>
    </Row>

    <Tabs value="brands" class="text-white">
        <TabPane label="报备品牌" name="brands">
            <Table stripe  :columns="brandColumn"  :data="brands"></Table>
            <div class="pull-right" style="margin-right:30px;margin-top:10px" v-if="allowEdit&& advertiser.source === 0">
                <a type="info" size="small" @click="handleAddBrand">添加品牌</a>
            </div>
        </TabPane>

        <TabPane label="联系人列表" name="contacts">
            <p v-if="contacts.length===0" class="text-center remark p-t-10">暂无数据</p>
            <Row :gutter="8">
                <!-- 显示已存在的联系列表 -->
                <i-col v-for="contact in contacts" :key="contact.id" :xs="24" :sm="12" :md="6" :lg="6">
                  <Card >
                      <a href="#" v-if="allowEdit&& advertiser.source === 0" slot="extra" @click.prevent="confirmDeleteContact(contact.id)">
                          <Icon type="md-close" />
                      </a>
                      <p class="text-14">{{ contact.name }}</p>
                      <P class="p-t-5"><span class="title">所在部门 </span> {{ contact.department }}</P>
                      <P class="p-t-5"><span class="title">职位 </span> {{ contact.position }}</P>
                      <P class="p-t-5"><span class="title">联系电话 </span> {{ contact.phone }}</P>
                      <P class="p-t-5 remark"><span class="title">最后更新时间 </span>{{ contact.createTime }}</P>
                  </Card>
                </i-col>
                <!-- 显示新增窗口 -->
                <i-col :xs="24" :sm="12" :md="6" :lg="6" v-if="allowEdit&& advertiser.source === 0">
                  <Form ref="contactData" :model="contactData" :rules="ruleValidate" label-position="left">
                      <Card>
                        <FormItem  prop="name">
                          <Input size="small" v-model="contactData.name" placeholder="联系人姓名" />
                        </FormItem>
                        <FormItem prop="phone">
                          <Input size="small" v-model="contactData.phone" placeholder="联系电话" />
                        </FormItem>
                        <FormItem prop="department">
                          <Input size="small" v-model="contactData.department" placeholder="部门" />
                        </FormItem>
                        <FormItem prop="position">
                          <Input size="small" v-model="contactData.position" placeholder="职务" />
                        </FormItem>
                        <Button type="success" size="small" class="pull-right" @click="contactHandleSubmit('contactData')">新增联系人</Button>
                      </Card>
                  </Form>
                </i-col>
            </Row>
        </TabPane>

        <TabPane label="变更记录" name="advertiserHistory">
          <Table  stripe :columns="advertiserHistoryColumn"  :data="advertiserHistory"></Table>
        </TabPane>

        <TabPane label="附件列表" name="files">
          <customer-files :customerId="query.advertiserId"></customer-files>
        </TabPane>
    </Tabs>

    <AddBrand ref="addBrandComponet" @on-success="ConfirmAddBrand"/>

    <Modal v-model="reportBrandModal"  :mask-closable="false"  width="400">
        <p slot="header" class="workplatform-title">
            <span>跨渠道报备客户品牌</span>
        </p>

        <Form ref="reportBrandData" class="text-center" :model="brandData" :rules="brandRuleValidate" label-position="left">
            <FormItem  prop="reprotcompanyId">
                <Select v-model="reprotcompanyId"  placeholder="请选择您将要报备的渠道">
                    <Option v-for="company in reportCompanyArray" :value="company.id" :key="company.id">{{company.name}}</Option>
                </Select>
            </FormItem>
        </Form>

        <div slot="footer">
             <Button type="text" @click="reportBrandModal=false">
                <span>取消</span>
              </Button>
              <Button type="primary" @click="confirmReportBrand" :disabled="!reprotcompanyId">
                <span>确定</span>
              </Button>
        </div>
    </Modal>

    <Modal v-model="showapprovalHistoryModal" :title="currentcheckBrand.name + '审批记录'" :footer-hide="true" :mask-closable="false" width='800'>
      <Row>
        <i-col span="24">

      <history :relateId="currentcheckBrand.companyBrandId" :type="6" v-if="showapprovalHistoryModal"></history>
          <!-- <Table  stripe :columns="approvalHistoryColumn"  :data="approvalHistory"></Table> -->
        </i-col>
      </Row>
    </Modal>

  </div>
</template>

<script>
// components
import CustomerFiles from '@/components/advertiser/CustomerFiles'
import AddBrand from '@/components/advertiser/AddBrand'
import History from '@/components/approval/History'
// apis
import { getContactList, getAdvertiser, addContact, deleteContact, getAdvertiserHistoryList, stoptask } from '@/api/crm/advertiser'
import { getBrandList, deleteBrand, addBrand } from '@/api/crm/brand'
import { getReportcompany, reportbrand } from '@/api/crm/companyadvertiser'
// utils
import { formatBrandStatus, formatApprovalResult, formatCustomerStatus } from '@/utils/tagStatus'

export default {
  components: {
    CustomerFiles, AddBrand, History
  },
  props: {
    id: {
      type: Number
    }
  },
  data () {
    return {
      reportBrandModal: false,
      reprotcompanyId: 0, // 选中的报备渠道Id
      reportBrandId: 0,
      reportCompanyArray: [], // 可报备的渠道

      approvalHistory: [],

      approvalHistoryColumn: [
        {
          title: '审批结果',
          key: 'approvalResult',
          align: 'center',
          render: (h, params) => {
            return formatApprovalResult(h, parseInt(params.row.approvalResult), params.row.approvalResultName)
          }
        },
        {
          title: '审批状态',
          key: 'status',
          align: 'center',
          render: (h, params) => {
            return formatApprovalResult(h, parseInt(params.row.status), params.row.statusName)
          }
        },
        { title: '审批节点', key: 'approvalnodeName' },
        { title: '审批人', key: 'approverUserName' },
        { title: '审批时间', key: 'finishTime' },
        { title: '审批备注', key: 'comment' }
      ],
      contacts: [],
      brands: [], // 客户包含的品牌清单
      brandColumn: [
        { title: '品牌备注', key: 'name' },
        { title: '平台品牌', key: 'platformBrandName' },
        // { title: '品牌关键字', key: 'keyword' },
        { title: '所属行业', key: 'industryName' },
        { title: '所属渠道', key: 'companyName' },
        { title: '品牌空间', key: 'signName' },
        {
          title: '状态',
          key: 'status',
          align: 'center',
          width: 110,
          render: (h, params) => {
            return formatBrandStatus(h, params.row.status, params.row.statusName)
          }
        },
        { title: '报备时间', key: 'createTime' },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          width: 200,
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.currentcheckBrand = params.row
                    this.showapprovalHistoryModal = true
                  }
                }
              }, '审批记录'),

              this.allowReportcompanyfunction && params.row.companyId === this.$store.getters.token.userInfo.companyId && [2, 3, 5].includes(params.row.status) ? h('a', {
                props: {
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    if (!this.reportCompanyArray.length) {
                      getReportcompany({ brandId: params.row.id }).then(res => {
                        this.reportCompanyArray = res
                      })
                    }
                    this.reportBrandId = params.row.id
                    this.reportBrandModal = true
                  }
                }
              }, '跨渠道报备') : null,
              [1].includes(params.row.status) ? h('a', {
                style: {
                  marginRight: '5px',
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    let mark = ''
                    this.$Modal.confirm({
                      title: '撤回审批',
                      render: (h) => {
                        return h('Input', {
                          props: {
                            value: mark,
                            autofocus: true,
                            placeholder: '请填写撤回审批的备注信息'
                          },
                          on: {
                            input: (val) => {
                              mark = val
                            }
                          }
                        })
                      },
                      loading: true,
                      onOk: () => {
                        stoptask({
                          mark: mark,
                          brandId: params.row.id
                        }).then((res) => {
                          if (res && !res.errcode) {
                            this.$Notice.success({ desc: '撤回审批成功' })
                            this.initBrands()
                          }
                          this.$Modal.remove()
                        })
                          .catch((error) => {
                            this.$Modal.remove()
                            this.$Notice.error({
                              title: '服务器请求错误',
                              desc: error
                            })
                          })
                      }
                    })

                    // this.currentcheckBrand = params.row
                    // this.showapprovalHistoryModal = true
                  }
                }
              }, '撤回') : null,

              this.allowEdit && params.row.status !== 1 ? h('a', {
                props: {
                },
                style: {
                  marginRight: '5px',
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    this.confirmDeleteBrand(params.row)
                  }
                }
              }, '删除') : null
            ])
          }
        }
      ],

      advertiser: {
        id: null,
        name: '',
        orgCode: '',
        address: '',
        industryId: null,
        type: null,
        tel: '',
        description: '',
        userId: '',
        createTime: '',
        updateTime: ''
      },
      query: {
        advertiserId: '',
        contactId: '',
        userId: '',
        industryId: '',
        brandId: ''
      },
      contactData: {
        advertiserId: '',
        name: '',
        position: '',
        department: '',
        phone: '',
        userId: ''
      },
      ruleValidate: {
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        position: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        department: [{ required: true, message: ' ', trigger: 'blur' }],
        phone: [{ required: true, message: ' ', trigger: 'blur' },
          { pattern: /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/, message: ' ' }]
      },
      // 跨渠道报备客户品牌
      brandData: {
        advertiserId: 0,
        industryId: null,
        industryName: '',
        publisherId: '',
        userId: '',
        approval: !this.isAuth('Advertiser_Manage_View'),
        name: '',
        keyword: '',
        platformBrandId: null,
        platformBrandName: null,
        platformIndustryId: null,
        platformIndustryName: null
      },
      brandRuleValidate: {
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        industryId: [{ required: true, message: ' ' }]
      },
      advertiserHistory: [],

      advertiserHistoryColumn: [
        {
          title: '代理商',
          key: 'companyName',
          align: 'center'
        },
        {
          title: '状态',
          key: 'statusName ',
          align: 'center',
          render: (h, params) => {
            return formatCustomerStatus(h, parseInt(params.row.status), params.row.statusName)
          }
        },
        {
          title: '变更时间',
          key: 'createTime'
        }
      ],
      showapprovalHistoryModal: false,
      currentcheckBrand: ''
    }
  },
  methods: {
    initAdvertiserInfo () {
      this.query.advertiserId = this.detailId
      this.contactData.advertiserId = this.detailId
      this.brandData.advertiserId = this.detailId

      this.contactData.userId = this.$store.getters.token.userInfo.userId
      this.brandData.userId = this.$store.getters.token.userInfo.userId

      getAdvertiser(this.query).then(res => {
        this.advertiser = res
        this.brandData.publisherId = res.publisherId
        if (res.source !== 0) {
          this.query.companyId = this.$store.getters.token.userInfo.companyId
        }
        this.initAdvertiserHistories()
        this.initContacts()
        this.initBrands()
      })
    },
    // 确认报备品牌到其他渠道
    confirmReportBrand () {
      const that = this
      reportbrand({ brandId: that.reportBrandId, companyId: that.reprotcompanyId }).then(res => {
        if (res && !res.errcode) {
          that.$Notice.success({ desc: '报备成功！' })
          that.initBrands()
          that.reportBrandModal = false
        }
      })
    },
    initContacts () {
      getContactList(this.query).then(res => {
        this.contacts = res
      })
    },
    initBrands () {
      getBrandList(this.query).then(res => {
        this.brands = res
      })
    },

    initAdvertiserHistories () {
      getAdvertiserHistoryList({ advertiserId: this.detailId }).then(res => {
        this.advertiserHistory = res
      })
    },
    handleAddBrand () {
      this.$refs.addBrandComponet.showModal()
    },
    contactHandleSubmit (dataname) {
      this.$refs[dataname].validate(valid => {
        if (valid) {
          addContact(this.contactData).then(res => {
            if (res) {
              this.$refs[dataname].resetFields()
              this.initContacts()
            }
          })
        }
      })
    },
    confirmDeleteContact (val) {
      this.$Modal.confirm({
        title: '确定删除？',
        content: '<p>确定要删除该条数据，删除后将不可恢复！</p>',
        loading: true,
        onOk: () => {
          this.query.contactId = val
          this.deleteContact()
          this.$Modal.remove()
        }
      })
    },
    deleteContact () {
      deleteContact(this.query).then(res => {
        if (res && !res.errcode) {
          this.initContacts()
          this.$Notice.success({ desc: '删除联系人成功。' })
        }
      })
    },
    ConfirmAddBrand (brandBean) {
      this.brandData.industryId = brandBean.industryId
      this.brandData.industryName = brandBean.industryName
      this.brandData.keyword = brandBean.keyword
      this.brandData.name = brandBean.name

      this.brandData.sign = 1

      this.brandData.platformBrandId = brandBean.platformBrandId
      this.brandData.platformBrandName = brandBean.platformBrandName
      this.brandData.platformIndustryId = brandBean.platformIndustryId
      this.brandData.platformIndustryName = brandBean.platformIndustryName

      addBrand(this.brandData).then(res => {
        if (res && !res.errcode) {
          this.initBrands()
          this.$Notice.success({ desc: '添加品牌成功。' })
        }
      })
    },
    confirmDeleteBrand (brand) {
      this.$Modal.confirm({
        title: '确定删除？',
        content: '<p>确定要删除该条数据，删除后将不可恢复！</p>',
        loading: true,
        onOk: () => {
          deleteBrand({ brandId: brand.id, companyId: brand.companyId }).then(res => {
            if (res && !res.errcode) {
              this.initBrands()
              this.$Notice.success({ desc: '删除客户品牌成功。' })
            }
          })
          this.$Modal.remove()
        }
      })
    }
  },
  created () {
    this.initAdvertiserInfo()
  },
  computed: {
    detailId () {
      return this.id || this.$store.state.advertiser.detailId
    },
    allowEdit: function () {
      // let startDate = new Date(this.contract.startDate)
      return this.isAuth('Advertiser_Edit')
    },
    allowReportcompanyfunction () {
      // let startDate = new Date(this.contract.startDate)
      return this.isAuth('Advertiser_Reportcompany') && this.$store.getters.token.publisherSetting.crossChannelReport && this.advertiser.source === 0
    }

  }
}
</script>
